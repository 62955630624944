import request from '@/utils/request';

/**
 * 保存，发布轮播图区域设置
 * @param { siteId :1,轮播图，2 导航栏，3推荐区域, status :1发布（h还远），2保存} data
 * @returns
 */
export function bannerSave(data) {
  return request({
    url: 'misc-web-api/admin/banner/save',
    method: 'POST',
    data
  });
}

/**
 * 修改轮播图图片
 * @param data
 * @returns {AxiosPromise}
 */
// export function updateSwiperImage(data) {
//   return request({
//     url: "misc-web-api/admin/banner/edit",
//     method: 'POST',
//     data,
//   })
// }

/**
 * 列表展示轮播图，导航栏
 * @param data
 * @returns {AxiosPromise}
 */
export function getBannerOrNavigationList(data) {
  return request({
    url: 'misc-web-api/admin/banner/getList',
    method: 'POST',
    data
  });
}

/**
 * 还原轮播图，导航栏
 * @param data
 * @returns {AxiosPromise}
 */
export function backBannerOrNavigation(data) {
  return request({
    url: 'misc-web-api/admin/banner/back',
    method: 'POST',
    data
  });
}

/**
 * 删除bannner
 * @param data
 * @returns {AxiosPromise}
 */
export function deleteBanner(data) {
  return request({
    url: 'misc-web-api/admin/banner/del',
    method: 'POST',
    data
  });
}

// 推荐区域

/**
 * 发布推荐区域
 * @param {*} data
 * @returns
 */
export function recommendAreaPublish(data) {
  return request({
    url: 'misc-web-api/admin/recommendArea/publish',
    method: 'POST',
    data
  });
}

/**
 * 发布推荐区域
 * @param {*} data
 * @returns
 */
export function recommendJpgAreaPublish(data) {
  return request({
    url: 'misc-web-api/admin/recommendArea/jpg/publish',
    method: 'POST',
    data
  });
}

/**
 * 保存推荐区域
 * @param {*} data
 * @returns
 */
export function recommendAreaSave(data) {
  return request({
    url: 'misc-web-api/admin/recommendArea/save',
    method: 'POST',
    data
  });
}

/**
 * 保存推荐区域
 * @param {*} data
 * @returns
 */
export function recommendJpgAreaSave(data) {
  return request({
    url: 'misc-web-api/admin/recommendArea/jpg/save',
    method: 'POST',
    data
  });
}
/**
 * 获取推荐区域设置
 * @param {*} data
 * @returns
 */
export function recommendAreaList(data) {
  return request({
    url: 'misc-web-api/admin/recommendArea/getList',
    method: 'POST',
    data
  });
}
/**
 * 获取推荐区域设置
 * @param {*} data
 * @returns
 */
export function recommendJpgAreaList(data) {
  return request({
    url: 'misc-web-api/admin/recommendArea/jpg/getList',
    method: 'POST',
    data
  });
}
/**
 * 删除推荐区域
 * @param {*} data
 * @returns
 */
export function recommendAreaDelete(data) {
  return request({
    url: 'misc-web-api/admin/recommendArea/delete',
    method: 'POST',
    data
  });
}
/**
 * 删除推荐区域
 * @param {*} data
 * @returns
 */
export function recommendJpgAreaDelete(data) {
  return request({
    url: 'misc-web-api/admin/recommendArea/jpg/delete',
    method: 'POST',
    data
  });
}
export function recommendAreaBack(data) {
  return request({
    url: 'misc-web-api/admin/recommendArea/back',
    method: 'POST',
    data
  });
}
export function recommendJpgAreaBack(data) {
  return request({
    url: 'misc-web-api/admin/recommendArea/jpg/back',
    method: 'POST',
    data
  });
}

export function saveScore(data) {
  return request({
    url: 'misc-web-api/admin/dictionary/editBehavior',
    method: 'POST',
    data
  });
}

export function cityProjectList(data) {
  return request({
    url: 'misc-web-api/admin/recommendTopic/list',
    method: 'POST',
    data
  });
}

export function cityProjectDel(data) {
  return request({
    url: 'misc-web-api/admin/recommendTopic/delete',
    method: 'POST',
    data
  });
}

export function cityProjectSave(data) {
  return request({
    url: 'misc-web-api/admin/recommendTopic/save',
    method: 'POST',
    data
  });
}

export function cityProjectDet(data) {
  return request({
    url: 'misc-web-api/admin/recommendTopic/detail',
    method: 'POST',
    data
  });
}

export function getBrandList(data) {
  return request({
    url: 'misc-web-api/admin/recommendBrand/getList',
    method: 'POST',
    data
  });
}

export function publishOrSaveBrand(data) {
  return request({
    url: 'misc-web-api/admin/recommendBrand/publishOrSave',
    method: 'POST',
    data
  });
}

export function deleteBrand(data) {
  return request({
    url: 'misc-web-api/admin/recommendBrand/delete',
    method: 'POST',
    data
  });
}

export function restoreBrand(data) {
  return request({
    url: 'misc-web-api/admin/recommendBrand/back',
    method: 'POST',
    data
  });
}

export function getClassificationList(data) {
  return request({
    url: 'misc-web-api/admin/configuration/management/list',
    method: 'POST',
    data
  });
}
